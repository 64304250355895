import moment from 'moment';
import actions from './actions';
import format from '../../config/format';
import localStorageControl from '../../utility/localStorageControl';

const initialIndexState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
  },
  filters: {},
  sorter: {},
  loading: false,
  deleting: false,
  error: null,
};

const {
  CARD_MAP_VIEWS_PAGINATION_UPDATE_SUCCESS,
  CARD_MAP_VIEWS_FILTERS_UPDATE_SUCCESS,
  CARD_MAP_VIEWS_SORTER_UPDATE_SUCCESS,

  CARD_MAP_VIEWS_UPDATE_BEGIN,
  CARD_MAP_VIEWS_UPDATE_SUCCESS,
  CARD_MAP_VIEWS_UPDATE_ERR,

  CARD_MAP_VIEWS_CLEANUP_BEGIN,
  CARD_MAP_VIEWS_CLEANUP_SUCCESS,
  CARD_MAP_VIEWS_CLEANUP_ERR,

  CARD_MAP_VIEW_DELETE_BEGIN,
  CARD_MAP_VIEW_DELETE_SUCCESS,
  CARD_MAP_VIEW_DELETE_ERR,
} = actions;

const cardMapViewsReducer = (state = initialIndexState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CARD_MAP_VIEWS_PAGINATION_UPDATE_SUCCESS:
      return {
        ...state,
        pagination: data,
      };

    case CARD_MAP_VIEWS_FILTERS_UPDATE_SUCCESS:
      return {
        ...state,
        filters: data,
      };

    case CARD_MAP_VIEWS_SORTER_UPDATE_SUCCESS:
      return {
        ...state,
        sorter: data,
      };

    case CARD_MAP_VIEWS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_MAP_VIEWS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CARD_MAP_VIEWS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_MAP_VIEWS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_MAP_VIEWS_CLEANUP_SUCCESS:
      return initialIndexState;

    case CARD_MAP_VIEWS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_MAP_VIEW_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case CARD_MAP_VIEW_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case CARD_MAP_VIEW_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

// filters
const cardMapViewMapFiltersDefault = {
  'Cards.due_date': [moment().startOf('month'), moment().endOf('month')],
};

const initialMapState = {
  data: undefined,
  filters: cardMapViewMapFiltersDefault,
  loading: false,
  saving: false,
  error: null,
};

const {
  CARD_MAP_VIEW_UPDATE_BEGIN,
  CARD_MAP_VIEW_UPDATE_SUCCESS,
  CARD_MAP_VIEW_UPDATE_ERR,

  CARD_MAP_VIEW_MAP_BEGIN,
  CARD_MAP_VIEW_MAP_SUCCESS,
  CARD_MAP_VIEW_MAP_ERR,

  CARD_MAP_VIEW_FILTERS_UPDATE_SUCCESS,

  CARD_MAP_VIEW_CLEANUP_BEGIN,
  CARD_MAP_VIEW_CLEANUP_SUCCESS,
  CARD_MAP_VIEW_CLEANUP_ERR,

  CARD_MAP_VIEW_SAVE_BEGIN,
  CARD_MAP_VIEW_SAVE_SUCCESS,
  CARD_MAP_VIEW_SAVE_ERR,
} = actions;

const cardMapViewReducer = (state = initialMapState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CARD_MAP_VIEW_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_MAP_VIEW_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CARD_MAP_VIEW_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_MAP_VIEW_MAP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_MAP_VIEW_MAP_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CARD_MAP_VIEW_MAP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_MAP_VIEW_FILTERS_UPDATE_SUCCESS:
      localStorageControl.setItem('CardMapViewMap.filters', format.output.momentObj(data, ['Cards.due_date']));
      return {
        ...state,
        filters: data,
      };

    case CARD_MAP_VIEW_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_MAP_VIEW_CLEANUP_SUCCESS:
      return initialMapState;

    case CARD_MAP_VIEW_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_MAP_VIEW_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case CARD_MAP_VIEW_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case CARD_MAP_VIEW_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { cardMapViewsReducer, cardMapViewReducer, cardMapViewMapFiltersDefault };
