import { matchPath } from 'react-router';
import rules from './rules';
import CONSTANTS from '../constants';

// Profiles Tree
const { CLIENTE, ESTAGIARIO, EXECUTOR, ANALISTA, ESPECIALISTA, SUPERVISOR, COORDENADOR, GESTOR } = CONSTANTS.PROFILE;
const PROFILES_TREE = {
  [GESTOR]: [COORDENADOR, SUPERVISOR, ESPECIALISTA, ANALISTA, EXECUTOR, ESTAGIARIO],
  [COORDENADOR]: [SUPERVISOR, ESPECIALISTA, ANALISTA, EXECUTOR, ESTAGIARIO],
  [SUPERVISOR]: [ESPECIALISTA, ANALISTA, EXECUTOR, ESTAGIARIO],
  [ESPECIALISTA]: [ANALISTA, EXECUTOR, ESTAGIARIO],
  [ANALISTA]: [EXECUTOR, ESTAGIARIO],
  [EXECUTOR]: [ESTAGIARIO],
  [ESTAGIARIO]: [],
  [CLIENTE]: [],
};

/*
 * ACL Functions
 */

/**
 * isUserProfileAllowed
 *
 * @param {*} user
 * @param {*} profile_id
 * @returns
 */
const isUserProfileAllowed = (user, profile_id) => {
  return (
    user?.is_admin ||
    user?.users_departments
      ?.map(
        user_department =>
          user_department.profile_id === profile_id || PROFILES_TREE[user_department.profile_id]?.includes(profile_id),
      )
      ?.includes(true)
  );
};

/**
 * isUserDepartmentAllowed
 *
 * @param {*} user
 * @param {*} department_id
 * @param {*} profile_id
 * @returns
 */
const isUserDepartmentAllowed = (user, department_id, profile_id = null) => {
  return (
    user?.is_admin ||
    user?.users_departments.filter(user_department => {
      return (
        user_department.department_id === department_id &&
        (profile_id === null || isUserProfileAllowed(user, profile_id))
      );
    }).length > 0
  );
};

/**
 * isUserEmployeeAllowed
 *
 * @param {*} user
 * @param {*} employee_id
 * @returns
 */
const isUserEmployeeAllowed = (user, employee_id) =>
  user?.is_admin ||
  employee_id === user?.id ||
  user?.employees_ids.filter(employeeId => employeeId === employee_id).length > 0;

/**
 * isUserEmployerAllowed
 *
 * @param {*} user
 * @param {*} employer_id
 * @returns
 */
const isUserEmployerAllowed = (user, employer_id) =>
  user?.is_admin || user?.employers_ids.filter(employerId => employerId === employer_id).length > 0;

/**
 * isUserClientAllowed
 *
 * @param {*} user
 * @param {*} employer_id
 * @returns
 */
const isUserClientAllowed = (user, client_id) =>
  user?.is_admin || user?.clients.filter(client => client.id === client_id).length > 0;

/**
 * isUserEmployee
 *
 * @param {*} user
 * @returns
 */
const isUserEmployee = user => {
  return (
    user?.is_admin === true ||
    user?.client === false ||
    CONSTANTS.COLABORADORES_PROFILES.map(profile_id => isUserProfileAllowed(user, profile_id)).includes(true)
  );
};

/**
 * isUserEmployeePublic
 *
 * Verifica se o usuário pertence a algum setor que é público para clientes,
 * usuários desses setores não são vinculados à clientes.
 *
 * Ex: TI, RH, Recepção, etc.
 *
 *
 * @param {*} user
 * @returns
 */
const isUserEmployeePublic = user =>
  isUserEmployee(user) &&
  user?.users_departments.filter(user_department => user_department.department.employee_public).length > 0;

/**
 * isUserClientPublic
 *
 * Verifica se o usuário pertence a algum setor que é público para clientes,
 * usuários desses setores não são vinculados à clientes.
 *
 * Ex: TI, RH, Recepção, etc.
 *
 *
 * @param {*} user
 * @returns
 */
const isUserClientPublic = user =>
  !isUserEmployee(user) &&
  user?.users_departments.filter(user_department => user_department.department.client_public).length > 0;

/**
 * isUserClient
 *
 * @param {*} user
 * @returns
 */
const isUserClient = user => !!user?.client;

/**
 * getPathRules
 *
 * @param {*} path Examples: '/admin/clients/view/2109', '/admin/clients/view/:id'
 * @returns
 */
const getPathRules = path =>
  Object.keys(rules)
    .filter(key =>
      matchPath(path, {
        path: key,
        exact: true,
        strict: false,
      }),
    )
    .reduce((res, key) => Object.assign(res, rules[key]), {}) ?? rules[path];

/**
 * isUserACLAllowed
 *
 * @param {*} identity { data: null, loading: false, error: null }
 * @param {*} path
 * @returns
 */
const isUserACLAllowed = (identity, path) => {
  const pathRules = getPathRules(path);

  // identity loading
  if (identity.loading || identity.data?.user === null) return false;

  // pathRules (if pathRules is null, path is allowed)
  if (pathRules && Object.keys(pathRules).length > 0 && Object.getPrototypeOf(pathRules) === Object.prototype) {
    const response =
      // isAdmin
      identity.data.user?.is_admin ||
      // User allowed
      (pathRules?.users && pathRules?.users.includes(identity.data.user.id)) ||
      // UserDepartment allowed
      identity.data.user.users_departments.filter(
        user_department =>
          pathRules['*'] === '*' ||
          pathRules['*']?.includes(user_department.profile_id) ||
          pathRules[user_department.department_id] === '*' ||
          pathRules[user_department.department_id]?.includes(user_department.profile_id),
      ).length > 0;

    return response;
  }

  // if user is client, requires pathRules has client profile
  if (isUserClient(identity.data.user)) {
    return false;
  }

  return true;
};

export {
  getPathRules,
  isUserACLAllowed,
  isUserProfileAllowed,
  isUserDepartmentAllowed,
  isUserEmployeeAllowed,
  isUserEmployeePublic,
  isUserEmployerAllowed,
  isUserClientAllowed,
  isUserClientPublic,
  isUserEmployee,
  isUserClient,
};
