const actions = {
  FILE_FOLDERS_LIST_UPDATE_BEGIN: 'FILE_FOLDERS_LIST_UPDATE_BEGIN',
  FILE_FOLDERS_LIST_UPDATE_SUCCESS: 'FILE_FOLDERS_LIST_UPDATE_SUCCESS',
  FILE_FOLDERS_LIST_UPDATE_ERR: 'FILE_FOLDERS_LIST_UPDATE_ERR',

  fileFoldersListUpdateBegin: () => {
    return {
      type: actions.FILE_FOLDERS_LIST_UPDATE_BEGIN,
    };
  },

  fileFoldersListUpdateSuccess: data => {
    return {
      type: actions.FILE_FOLDERS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  fileFoldersListUpdateErr: err => {
    return {
      type: actions.FILE_FOLDERS_LIST_UPDATE_ERR,
      err,
    };
  },

  FILE_FOLDERS_LIST_CLEANUP_BEGIN: 'FILE_FOLDERS_LIST_CLEANUP_BEGIN',
  FILE_FOLDERS_LIST_CLEANUP_SUCCESS: 'FILE_FOLDERS_LIST_CLEANUP_SUCCESS',
  FILE_FOLDERS_LIST_CLEANUP_ERR: 'FILE_FOLDERS_LIST_CLEANUP_ERR',

  fileFoldersListCleanupBegin: () => {
    return {
      type: actions.FILE_FOLDERS_LIST_CLEANUP_BEGIN,
    };
  },

  fileFoldersListCleanupSuccess: data => {
    return {
      type: actions.FILE_FOLDERS_LIST_CLEANUP_SUCCESS,
      data,
    };
  },

  fileFoldersListCleanupErr: err => {
    return {
      type: actions.FILE_FOLDERS_LIST_CLEANUP_ERR,
      err,
    };
  },

  FILE_FOLDERS_TREE_UPDATE_BEGIN: 'FILE_FOLDERS_TREE_UPDATE_BEGIN',
  FILE_FOLDERS_TREE_UPDATE_SUCCESS: 'FILE_FOLDERS_TREE_UPDATE_SUCCESS',
  FILE_FOLDERS_TREE_UPDATE_ERR: 'FILE_FOLDERS_TREE_UPDATE_ERR',

  fileFoldersTreeUpdateBegin: () => {
    return {
      type: actions.FILE_FOLDERS_TREE_UPDATE_BEGIN,
    };
  },

  fileFoldersTreeUpdateSuccess: data => {
    return {
      type: actions.FILE_FOLDERS_TREE_UPDATE_SUCCESS,
      data,
    };
  },

  fileFoldersTreeUpdateErr: err => {
    return {
      type: actions.FILE_FOLDERS_TREE_UPDATE_ERR,
      err,
    };
  },

  FILE_FOLDERS_TREE_CLEANUP_BEGIN: 'FILE_FOLDERS_TREE_CLEANUP_BEGIN',
  FILE_FOLDERS_TREE_CLEANUP_SUCCESS: 'FILE_FOLDERS_TREE_CLEANUP_SUCCESS',
  FILE_FOLDERS_TREE_CLEANUP_ERR: 'FILE_FOLDERS_TREE_CLEANUP_ERR',

  fileFoldersTreeCleanupBegin: () => {
    return {
      type: actions.FILE_FOLDERS_TREE_CLEANUP_BEGIN,
    };
  },

  fileFoldersTreeCleanupSuccess: data => {
    return {
      type: actions.FILE_FOLDERS_TREE_CLEANUP_SUCCESS,
      data,
    };
  },

  fileFoldersTreeCleanupErr: err => {
    return {
      type: actions.FILE_FOLDERS_TREE_CLEANUP_ERR,
      err,
    };
  },

  FILE_FOLDERS_CLEANUP_BEGIN: 'FILE_FOLDERS_CLEANUP_BEGIN',
  FILE_FOLDERS_CLEANUP_SUCCESS: 'FILE_FOLDERS_CLEANUP_SUCCESS',
  FILE_FOLDERS_CLEANUP_ERR: 'FILE_FOLDERS_CLEANUP_ERR',

  fileFoldersCleanupBegin: () => {
    return {
      type: actions.FILE_FOLDERS_CLEANUP_BEGIN,
    };
  },

  fileFoldersCleanupSuccess: data => {
    return {
      type: actions.FILE_FOLDERS_CLEANUP_SUCCESS,
      data,
    };
  },

  fileFoldersCleanupErr: err => {
    return {
      type: actions.FILE_FOLDERS_CLEANUP_ERR,
      err,
    };
  },

  FILE_FOLDER_DELETE_BEGIN: 'FILE_FOLDER_DELETE_BEGIN',
  FILE_FOLDER_DELETE_SUCCESS: 'FILE_FOLDER_DELETE_SUCCESS',
  FILE_FOLDER_DELETE_ERR: 'FILE_FOLDER_DELETE_ERR',

  fileFolderDeleteBegin: () => {
    return {
      type: actions.FILE_FOLDER_DELETE_BEGIN,
    };
  },

  fileFolderDeleteSuccess: data => {
    return {
      type: actions.FILE_FOLDER_DELETE_SUCCESS,
      data,
    };
  },

  fileFolderDeleteErr: err => {
    return {
      type: actions.FILE_FOLDER_DELETE_ERR,
      err,
    };
  },

  FILE_FOLDER_UPDATE_BEGIN: 'FILE_FOLDER_UPDATE_BEGIN',
  FILE_FOLDER_UPDATE_SUCCESS: 'FILE_FOLDER_UPDATE_SUCCESS',
  FILE_FOLDER_UPDATE_ERR: 'FILE_FOLDER_UPDATE_ERR',

  fileFolderUpdateBegin: () => {
    return {
      type: actions.FILE_FOLDER_UPDATE_BEGIN,
    };
  },

  fileFolderUpdateSuccess: data => {
    return {
      type: actions.FILE_FOLDER_UPDATE_SUCCESS,
      data,
    };
  },

  fileFolderUpdateErr: err => {
    return {
      type: actions.FILE_FOLDER_UPDATE_ERR,
      err,
    };
  },

  FILE_FOLDER_CLEANUP_BEGIN: 'FILE_FOLDER_CLEANUP_BEGIN',
  FILE_FOLDER_CLEANUP_SUCCESS: 'FILE_FOLDER_CLEANUP_SUCCESS',
  FILE_FOLDER_CLEANUP_ERR: 'FILE_FOLDER_CLEANUP_ERR',

  fileFolderCleanupBegin: () => {
    return {
      type: actions.FILE_FOLDER_CLEANUP_BEGIN,
    };
  },

  fileFolderCleanupSuccess: () => {
    return {
      type: actions.FILE_FOLDER_CLEANUP_SUCCESS,
    };
  },

  fileFolderCleanupErr: err => {
    return {
      type: actions.FILE_FOLDER_CLEANUP_ERR,
      err,
    };
  },

  FILE_FOLDER_SAVE_BEGIN: 'FILE_FOLDER_SAVE_BEGIN',
  FILE_FOLDER_SAVE_SUCCESS: 'FILE_FOLDER_SAVE_SUCCESS',
  FILE_FOLDER_SAVE_ERR: 'FILE_FOLDER_SAVE_ERR',

  fileFolderSaveBegin: () => {
    return {
      type: actions.FILE_FOLDER_SAVE_BEGIN,
    };
  },

  fileFolderSaveSuccess: data => {
    return {
      type: actions.FILE_FOLDER_SAVE_SUCCESS,
      data,
    };
  },

  fileFolderSaveErr: err => {
    return {
      type: actions.FILE_FOLDER_SAVE_ERR,
      err,
    };
  },
};

export default actions;
  