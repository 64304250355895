import { combineReducers } from 'redux';
import {
  achievementsListReducer,
  achievementsReducer,
  achievementReducer,
  achievementImageReducer,
} from './achievements/reducers';
import {
  achievementProductsListReducer,
  achievementProductsReducer,
  achievementProductReducer,
  achievementProductImageReducer,
} from './achievementProducts/reducers';
import { addressReducer } from './addresses/reducers';
import calendarHolidaysListReducer from './calendarHolidays/reducers';
import {
  cardReducer,
  cardsReducer,
  cardFilesReducer,
  cardsChartsReducer,
  cardsSummaryReducer,
  cardsReportReducer,
  cardsEmployeesReportReducer,
  cardExistsReducer,
  cardsPendingChartReducer,
  cardsQuestionsReportReducer,
} from './cards/reducers';
import cardStatusesListReducer from './cardStatuses/reducers';
import { cardTemplatesReducer, cardTemplateReducer, cardTemplatesListReducer } from './cardTemplates/reducers';
import cardTemplateRecurrencesListReducer from './cardTemplateRecurrences/reducers';
import cardTypesListReducer from './cardTypes/reducers';
import { cardJustificationReducer, cardJustificationsReducer } from './cardJustifications/reducers';
import {
  cardJustificationReasonReducer,
  cardJustificationReasonsReducer,
  cardJustificationReasonsListReducer,
} from './cardJustificationReasons/reducers';
import { clientsReducer, clientsListReducer, clientReducer, clientsReportReducer } from './clients/reducers';
import { cardReviewReducer, cardReviewsReducer } from './cardReviews/reducers';
import { clientStatusesChartsReducer, clientStatusesListReducer } from './clientStatuses/reducers';
import clientTypesListReducer from './clientTypes/reducers';
import { clientUsersListReducer, clientUsersReducer, clientUserReducer } from './clientUsers/reducers';
import {
  clientEmployeesReducer,
  clientEmployeeReducer,
  clientEmployeesReportReducer,
  clientEmployeesMatchReportReducer,
  clientEmployeesListReducer,
} from './clientEmployees/reducers';
import { clientFilesReducer, clientFilesListReducer, clientFileReducer } from './clientFiles/reducers';
import { clientAttorneyReducer, clientAttorneysReducer } from './clientAttorneys/reducers';
import clientAttorneySignersListReducer from './clientAttorneySigners/reducers';
import clientAttorneyTypesListReducer from './clientAttorneyTypes/reducers';
import clientAttorneyGrantedsListReducer from './clientAttorneyGranteds/reducers';
import clientCnpjReducer from './clientCnpjs/reducers';
import clientCnpjSizesListReducer from './clientCnpjSizes/reducers';
import clientCnpjStatusesListReducer from './clientCnpjStatuses/reducers';
import clientCnpjLegalNaturesListReducer from './clientCnpjLegalNatures/reducers';
import clientCnpjMunicipalRegistrationStatusesListReducer from './clientCnpjMunicipalRegistrationStatuses/reducers';
import clientCnpjStateRegistrationStatusesListReducer from './clientCnpjStateRegistrationStatuses/reducers';
import clientCnpjEmployerUnionsListReducer from './clientCnpjEmployerUnions/reducers';
import clientCnpjEmployeeUnionsListReducer from './clientCnpjEmployeeUnions/reducers';
import clientCnpjEsocialStepsListReducer from './clientCnpjEsocialSteps/reducers';
import {
  clientContractReducer,
  clientContractsChartsReducer,
  clientContractsReducer,
  clientContractsReportReducer,
} from './clientContracts/reducers';
import clientContractItemTypesListReducer from './clientContractItemTypes/reducers';
import {
  clientDigitalCertificateReducer,
  clientDigitalCertificatesReducer,
} from './clientDigitalCertificates/reducers';
import clientDigitalCertificateTypesListReducer from './clientDigitalCertificateTypes/reducers';
import { clientLicenseReducer, clientLicensesReducer } from './clientLicenses/reducers';
import clientLicenseTypesListReducer from './clientLicenseTypes/reducers';
import {
  clientNegativeCertificateReducer,
  clientNegativeCertificatesReducer,
} from './clientNegativeCertificates/reducers';
import clientNegativeCertificateSignersListReducer from './clientNegativeCertificateSigners/reducers';
import { clientPartnersReducer, clientPartnerReducer } from './clientPartners/reducers';
import clientPartnerQualificationsListReducer from './clientPartnerQualifications/reducers';
import { clientPartnerActsReducer, clientPartnerActReducer } from './clientPartnerActs/reducers';
import clientPartnerActSignersListReducer from './clientPartnerActSigners/reducers';
import clientPasswordsReducer from './clientPasswords/reducers';
import { clientTaxBreakReducer, clientTaxBreaksReducer } from './clientTaxBreaks/reducers';
import { clientTaxationReducer, clientTaxationsReducer } from './clientTaxations/reducers';
import clientTaxationCalculationTypesListReducer from './clientTaxationCalculationTypes/reducers';
import clientTaxationFederalTypesListReducer from './clientTaxationFederalTypes/reducers';
import clientTaxationStateTypesListReducer from './clientTaxationStateTypes/reducers';
import clientTaxationMunicipalTypesListReducer from './clientTaxationMunicipalTypes/reducers';
import clientTaxationSocialSecurityTypesListReducer from './clientTaxationSocialSecurityTypes/reducers';
import clientTaxBreakSignersListReducer from './clientTaxBreakSigners/reducers';
import cnaesListReducer from './cnaes/reducers';
import { commentsReducer, commentReducer } from './comments/reducers';
import { emailSentsReducer } from './emailSents/reducers';
import { contactsReducer, contactReducer } from './contacts/reducers';
import countriesListReducer from './countries/reducers';
import {
  departmentsListReducer,
  departmentsReducer,
  departmentReducer,
  departmentsTreeReducer,
} from './departments/reducers';
import { newsletterReducer, newslettersListReducer, newslettersReducer } from './newsletters/reducers';
import { filesReducer, fileReducer, filesListReducer, filesChartsReducer } from './files/reducers';
import { fileCategoriesListReducer, fileCategoriesTreeReducer, fileCategoryReducer } from './fileCategories/reducers';
import { fileFoldersListReducer, fileFoldersTreeReducer, fileFolderReducer } from './fileFolders/reducers';
import historiesReducer from './histories/reducers';
import { notificationsListReducer, notificationsReducer, notificationReducer } from './notifications/reducers';
import openaiAssistantsListReducer from './openaiAssistants/reducers';
import { openaiThreadReducer, openaiThreadsReducer } from './openaiThreads/reducers';
import profilesListReducer from './profiles/reducers';
import questionTypesListReducer from './questionTypes/reducers';
import {
  queuedJobReducer,
  queuedJobsReducer,
  queuedJobsChartsReducer,
  queuedJobsListReducer,
} from './queuedJobs/reducers';
import {
  sefazCeDteMessageReducer,
  sefazCeDteMessagesReducer,
  sefazCeDteMessagesListReducer,
} from './sefazCeDteMessages/reducers';
import {
  sefazCeSigetReportReducer,
  sefazCeSigetReportsReducer,
  sefazCeSigetReportsListReducer,
} from './sefazCeSigetReports/reducers';
import { tagReducer, tagsListReducer } from './tags/reducers';
import {
  usersReducer,
  usersListReducer,
  usersBirthdaysListReducer,
  userReducer,
  userPhotoReducer,
  userRecoverPasswordReducer,
} from './users/reducers';
import {
  userAchievementReducer,
  userAchievementsReducer,
  userAchievementsListReducer,
} from './userAchievements/reducers';
import { userDepartmentReducer, userDepartmentsReducer } from './userDepartments/reducers';
import userStatusesListReducer from './userStatuses/reducers';
import clientProfilesListReducer from './clientProfiles/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { headerSearchReducer } from './headerSearch/reducers';
import chartContentReducer from './chartContent/reducers';
import Todo from './todo/reducers';
import Note from './note/reducers';
import Profile from './profile/reducers';
import Calender from './calendar/reducers';
import { clientCardTemplatesReportReducer } from './clientCardTemplates/reducers';
import { cardMapViewReducer, cardMapViewsReducer } from './cardMapViews/reducers';
import { economicGroupReducer, economicGroupsListReducer, economicGroupsReducer } from './economicGroups/reducers';
import { userClientReducer } from './userClients/reducers';
import accessesReducer from './accesses/reducers';
import cardPrioritiesListReducer from './cardPriorities/reducers';
import { assetReducer, assetsTreeReducer, assetsListReducer, assetsReducer } from './assets/reducers';
import {
  assetCategoryReducer,
  assetCategoriesListReducer,
  assetCategoriesTreeReducer,
} from './assetCategories/reducers';
import { reviewReducer, reviewsReducer } from './reviews/reducers';

const rootReducers = combineReducers({
  accesses: accessesReducer,

  achievement: achievementReducer,
  achievementImage: achievementImageReducer,
  achievements: achievementsReducer,
  achievementsList: achievementsListReducer,

  achievementProduct: achievementProductReducer,
  achievementProductImage: achievementProductImageReducer,
  achievementProducts: achievementProductsReducer,
  achievementProductsList: achievementProductsListReducer,

  address: addressReducer,

  asset: assetReducer,
  assets: assetsReducer,
  assetsTree: assetsTreeReducer,
  assetsList: assetsListReducer,
  assetCategory: assetCategoryReducer,
  assetCategoriesList: assetCategoriesListReducer,
  assetCategoriesTree: assetCategoriesTreeReducer,

  calendarHolidaysList: calendarHolidaysListReducer,

  card: cardReducer,
  cards: cardsReducer,
  cardExists: cardExistsReducer,
  cardFiles: cardFilesReducer,
  cardsSummary: cardsSummaryReducer,
  cardsCharts: cardsChartsReducer,
  cardsPendingChart: cardsPendingChartReducer,
  cardsReport: cardsReportReducer,
  cardsEmployeesReport: cardsEmployeesReportReducer,
  cardsQuestionsReport: cardsQuestionsReportReducer,
  cardStatusesList: cardStatusesListReducer,
  cardTypesList: cardTypesListReducer,
  cardPrioritiesList: cardPrioritiesListReducer,

  cardMapView: cardMapViewReducer,
  cardMapViews: cardMapViewsReducer,

  cardJustification: cardJustificationReducer,
  cardJustifications: cardJustificationsReducer,
  cardJustificationReason: cardJustificationReasonReducer,
  cardJustificationReasons: cardJustificationReasonsReducer,
  cardJustificationReasonsList: cardJustificationReasonsListReducer,

  cardReviews: cardReviewsReducer,
  cardReview: cardReviewReducer,

  cardTemplates: cardTemplatesReducer,
  cardTemplate: cardTemplateReducer,
  cardTemplatesList: cardTemplatesListReducer,

  cardTemplateRecurrencesList: cardTemplateRecurrencesListReducer,

  client: clientReducer,
  clients: clientsReducer,
  clientsList: clientsListReducer,
  clientsReport: clientsReportReducer,

  clientCardTemplatesReport: clientCardTemplatesReportReducer,
  clientEmployeesReport: clientEmployeesReportReducer,
  clientEmployeesMatchReport: clientEmployeesMatchReportReducer,

  clientStatusesCharts: clientStatusesChartsReducer,
  clientStatusesList: clientStatusesListReducer,
  clientTypesList: clientTypesListReducer,

  clientUser: clientUserReducer,
  clientUsers: clientUsersReducer,
  clientUsersList: clientUsersListReducer,

  clientEmployee: clientEmployeeReducer,
  clientEmployees: clientEmployeesReducer,
  clientEmployeesList: clientEmployeesListReducer,

  clientFile: clientFileReducer,
  clientFiles: clientFilesReducer,
  clientFilesList: clientFilesListReducer,

  clientAttorney: clientAttorneyReducer,
  clientAttorneys: clientAttorneysReducer,
  clientAttorneySignersList: clientAttorneySignersListReducer,
  clientAttorneyTypesList: clientAttorneyTypesListReducer,
  clientAttorneyGrantedsList: clientAttorneyGrantedsListReducer,

  clientCnpj: clientCnpjReducer,
  clientCnpjSizesList: clientCnpjSizesListReducer,
  clientCnpjStatusesList: clientCnpjStatusesListReducer,
  clientCnpjLegalNaturesList: clientCnpjLegalNaturesListReducer,
  clientCnpjMunicipalRegistrationStatusesList: clientCnpjMunicipalRegistrationStatusesListReducer,
  clientCnpjStateRegistrationStatusesList: clientCnpjStateRegistrationStatusesListReducer,
  clientCnpjEmployerUnionsList: clientCnpjEmployerUnionsListReducer,
  clientCnpjEmployeeUnionsList: clientCnpjEmployeeUnionsListReducer,
  clientCnpjEsocialStepsList: clientCnpjEsocialStepsListReducer,

  clientContract: clientContractReducer,
  clientContracts: clientContractsReducer,
  clientContractItemTypesList: clientContractItemTypesListReducer,
  clientContractsCharts: clientContractsChartsReducer,
  clientContractsReport: clientContractsReportReducer,

  clientDigitalCertificate: clientDigitalCertificateReducer,
  clientDigitalCertificates: clientDigitalCertificatesReducer,
  clientDigitalCertificateTypesList: clientDigitalCertificateTypesListReducer,

  clientLicense: clientLicenseReducer,
  clientLicenses: clientLicensesReducer,
  clientLicenseTypesList: clientLicenseTypesListReducer,

  clientNegativeCertificate: clientNegativeCertificateReducer,
  clientNegativeCertificates: clientNegativeCertificatesReducer,
  clientNegativeCertificateSignersList: clientNegativeCertificateSignersListReducer,

  clientPartner: clientPartnerReducer,
  clientPartners: clientPartnersReducer,
  clientPartnerQualificationsList: clientPartnerQualificationsListReducer,

  clientPartnerAct: clientPartnerActReducer,
  clientPartnerActs: clientPartnerActsReducer,
  clientPartnerActSignersList: clientPartnerActSignersListReducer,

  clientPasswords: clientPasswordsReducer,

  clientTaxation: clientTaxationReducer,
  clientTaxations: clientTaxationsReducer,
  clientTaxationCalculationTypesList: clientTaxationCalculationTypesListReducer,
  clientTaxationFederalTypesList: clientTaxationFederalTypesListReducer,
  clientTaxationStateTypesList: clientTaxationStateTypesListReducer,
  clientTaxationMunicipalTypesList: clientTaxationMunicipalTypesListReducer,
  clientTaxationSocialSecurityTypesList: clientTaxationSocialSecurityTypesListReducer,

  clientTaxBreak: clientTaxBreakReducer,
  clientTaxBreaks: clientTaxBreaksReducer,
  clientTaxBreakSignersList: clientTaxBreakSignersListReducer,

  cnaesList: cnaesListReducer,
  countriesList: countriesListReducer,

  contacts: contactsReducer,
  contact: contactReducer,

  comment: commentReducer,
  comments: commentsReducer,

  emailSents: emailSentsReducer,

  files: filesReducer,
  file: fileReducer,
  filesList: filesListReducer,
  filesCharts: filesChartsReducer,

  fileCategory: fileCategoryReducer,
  fileCategoriesList: fileCategoriesListReducer,
  fileCategoriesTree: fileCategoriesTreeReducer,

  fileFolder: fileFolderReducer,
  fileFoldersList: fileFoldersListReducer,
  fileFoldersTree: fileFoldersTreeReducer,

  histories: historiesReducer,

  departmentsList: departmentsListReducer,
  departmentsTree: departmentsTreeReducer,
  departments: departmentsReducer,
  department: departmentReducer,

  economicGroupsList: economicGroupsListReducer,
  economicGroups: economicGroupsReducer,
  economicGroup: economicGroupReducer,

  newslettersList: newslettersListReducer,
  newsletters: newslettersReducer,
  newsletter: newsletterReducer,

  notificationsList: notificationsListReducer,
  notifications: notificationsReducer,
  notification: notificationReducer,

  openaiAssistantsList: openaiAssistantsListReducer,
  openaiThread: openaiThreadReducer,
  openaiThreads: openaiThreadsReducer,

  profilesList: profilesListReducer,

  questionTypesList: questionTypesListReducer,

  queuedJob: queuedJobReducer,
  queuedJobs: queuedJobsReducer,
  queuedJobsCharts: queuedJobsChartsReducer,
  queuedJobsList: queuedJobsListReducer,

  reviews: reviewsReducer,
  review: reviewReducer,

  sefazCeDteMessage: sefazCeDteMessageReducer,
  sefazCeDteMessages: sefazCeDteMessagesReducer,
  sefazCeDteMessagesList: sefazCeDteMessagesListReducer,

  sefazCeSigetReport: sefazCeSigetReportReducer,
  sefazCeSigetReports: sefazCeSigetReportsReducer,
  sefazCeSigetReportsList: sefazCeSigetReportsListReducer,

  tag: tagReducer,
  tagsList: tagsListReducer,

  user: userReducer,
  userRecoverPassword: userRecoverPasswordReducer,
  userPhoto: userPhotoReducer,
  users: usersReducer,
  usersList: usersListReducer,
  usersBirthdaysList: usersBirthdaysListReducer,

  userClient: userClientReducer,

  userDepartment: userDepartmentReducer,
  userDepartments: userDepartmentsReducer,

  clientProfilesList: clientProfilesListReducer,

  userStatusesList: userStatusesListReducer,

  userAchievement: userAchievementReducer,
  userAchievements: userAchievementsReducer,
  userAchievementsList: userAchievementsListReducer,

  auth: authReducer,
  ChangeLayoutMode,

  headerSearchData: headerSearchReducer,
  chartContent: chartContentReducer,
  Todo,
  Note,
  Profile,
  Calender,
});

export default rootReducers;
