const actions = {
  CARD_MAP_VIEWS_PAGINATION_UPDATE_BEGIN: 'CARD_MAP_VIEWS_PAGINATION_UPDATE_BEGIN',
  CARD_MAP_VIEWS_PAGINATION_UPDATE_SUCCESS: 'CARD_MAP_VIEWS_PAGINATION_UPDATE_SUCCESS',
  CARD_MAP_VIEWS_PAGINATION_UPDATE_ERR: 'CARD_MAP_VIEWS_PAGINATION_UPDATE_ERR',

  cardMapViewsPaginationUpdateBegin: () => {
    return {
      type: actions.CARD_MAP_VIEWS_PAGINATION_UPDATE_BEGIN,
    };
  },

  cardMapViewsPaginationUpdateSuccess: data => {
    return {
      type: actions.CARD_MAP_VIEWS_PAGINATION_UPDATE_SUCCESS,
      data,
    };
  },

  cardMapViewsPaginationUpdateErr: err => {
    return {
      type: actions.CARD_MAP_VIEWS_PAGINATION_UPDATE_ERR,
      err,
    };
  },

  CARD_MAP_VIEWS_FILTERS_UPDATE_BEGIN: 'CARD_MAP_VIEWS_FILTERS_UPDATE_BEGIN',
  CARD_MAP_VIEWS_FILTERS_UPDATE_SUCCESS: 'CARD_MAP_VIEWS_FILTERS_UPDATE_SUCCESS',
  CARD_MAP_VIEWS_FILTERS_UPDATE_ERR: 'CARD_MAP_VIEWS_FILTERS_UPDATE_ERR',

  cardMapViewsFiltersUpdateBegin: () => {
    return {
      type: actions.CARD_MAP_VIEWS_FILTERS_UPDATE_BEGIN,
    };
  },

  cardMapViewsFiltersUpdateSuccess: data => {
    return {
      type: actions.CARD_MAP_VIEWS_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  cardMapViewsFiltersUpdateErr: err => {
    return {
      type: actions.CARD_MAP_VIEWS_FILTERS_UPDATE_ERR,
      err,
    };
  },

  CARD_MAP_VIEWS_SORTER_UPDATE_BEGIN: 'CARD_MAP_VIEWS_SORTER_UPDATE_BEGIN',
  CARD_MAP_VIEWS_SORTER_UPDATE_SUCCESS: 'CARD_MAP_VIEWS_SORTER_UPDATE_SUCCESS',
  CARD_MAP_VIEWS_SORTER_UPDATE_ERR: 'CARD_MAP_VIEWS_SORTER_UPDATE_ERR',

  cardMapViewsSorterUpdateBegin: () => {
    return {
      type: actions.CARD_MAP_VIEWS_SORTER_UPDATE_BEGIN,
    };
  },

  cardMapViewsSorterUpdateSuccess: data => {
    return {
      type: actions.CARD_MAP_VIEWS_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  cardMapViewsSorterUpdateErr: err => {
    return {
      type: actions.CARD_MAP_VIEWS_SORTER_UPDATE_ERR,
      err,
    };
  },

  CARD_MAP_VIEWS_UPDATE_BEGIN: 'CARD_MAP_VIEWS_UPDATE_BEGIN',
  CARD_MAP_VIEWS_UPDATE_SUCCESS: 'CARD_MAP_VIEWS_UPDATE_SUCCESS',
  CARD_MAP_VIEWS_UPDATE_ERR: 'CARD_MAP_VIEWS_UPDATE_ERR',

  cardMapViewsUpdateBegin: () => {
    return {
      type: actions.CARD_MAP_VIEWS_UPDATE_BEGIN,
    };
  },

  cardMapViewsUpdateSuccess: data => {
    return {
      type: actions.CARD_MAP_VIEWS_UPDATE_SUCCESS,
      data,
    };
  },

  cardMapViewsUpdateErr: err => {
    return {
      type: actions.CARD_MAP_VIEWS_UPDATE_ERR,
      err,
    };
  },

  CARD_MAP_VIEWS_CLEANUP_BEGIN: 'CARD_MAP_VIEWS_CLEANUP_BEGIN',
  CARD_MAP_VIEWS_CLEANUP_SUCCESS: 'CARD_MAP_VIEWS_CLEANUP_SUCCESS',
  CARD_MAP_VIEWS_CLEANUP_ERR: 'CARD_MAP_VIEWS_CLEANUP_ERR',

  cardMapViewsCleanupBegin: () => {
    return {
      type: actions.CARD_MAP_VIEWS_CLEANUP_BEGIN,
    };
  },

  cardMapViewsCleanupSuccess: data => {
    return {
      type: actions.CARD_MAP_VIEWS_CLEANUP_SUCCESS,
      data,
    };
  },

  cardMapViewsCleanupErr: err => {
    return {
      type: actions.CARD_MAP_VIEWS_CLEANUP_ERR,
      err,
    };
  },

  CARD_MAP_VIEW_UPDATE_BEGIN: 'CARD_MAP_VIEW_UPDATE_BEGIN',
  CARD_MAP_VIEW_UPDATE_SUCCESS: 'CARD_MAP_VIEW_UPDATE_SUCCESS',
  CARD_MAP_VIEW_UPDATE_ERR: 'CARD_MAP_VIEW_UPDATE_ERR',

  cardMapViewUpdateBegin: () => {
    return {
      type: actions.CARD_MAP_VIEW_UPDATE_BEGIN,
    };
  },

  cardMapViewUpdateSuccess: data => {
    return {
      type: actions.CARD_MAP_VIEW_UPDATE_SUCCESS,
      data,
    };
  },

  cardMapViewUpdateErr: err => {
    return {
      type: actions.CARD_MAP_VIEW_UPDATE_ERR,
      err,
    };
  },

  CARD_MAP_VIEW_FILTERS_UPDATE_BEGIN: 'CARD_MAP_VIEW_FILTERS_UPDATE_BEGIN',
  CARD_MAP_VIEW_FILTERS_UPDATE_SUCCESS: 'CARD_MAP_VIEW_FILTERS_UPDATE_SUCCESS',
  CARD_MAP_VIEW_FILTERS_UPDATE_ERR: 'CARD_MAP_VIEW_FILTERS_UPDATE_ERR',

  cardMapViewFiltersUpdateBegin: () => {
    return {
      type: actions.CARD_MAP_VIEW_FILTERS_UPDATE_BEGIN,
    };
  },

  cardMapViewFiltersUpdateSuccess: data => {
    return {
      type: actions.CARD_MAP_VIEW_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  cardMapViewFiltersUpdateErr: err => {
    return {
      type: actions.CARD_MAP_VIEW_FILTERS_UPDATE_ERR,
      err,
    };
  },

  CARD_MAP_VIEW_MAP_BEGIN: 'CARD_MAP_VIEW_MAP_BEGIN',
  CARD_MAP_VIEW_MAP_SUCCESS: 'CARD_MAP_VIEW_MAP_SUCCESS',
  CARD_MAP_VIEW_MAP_ERR: 'CARD_MAP_VIEW_MAP_ERR',

  cardMapViewMapBegin: () => {
    return {
      type: actions.CARD_MAP_VIEW_MAP_BEGIN,
    };
  },

  cardMapViewMapSuccess: data => {
    return {
      type: actions.CARD_MAP_VIEW_MAP_SUCCESS,
      data,
    };
  },

  cardMapViewMapErr: err => {
    return {
      type: actions.CARD_MAP_VIEW_MAP_ERR,
      err,
    };
  },

  CARD_MAP_VIEW_CLEANUP_BEGIN: 'CARD_MAP_VIEW_CLEANUP_BEGIN',
  CARD_MAP_VIEW_CLEANUP_SUCCESS: 'CARD_MAP_VIEW_CLEANUP_SUCCESS',
  CARD_MAP_VIEW_CLEANUP_ERR: 'CARD_MAP_VIEW_CLEANUP_ERR',

  cardMapViewCleanupBegin: () => {
    return {
      type: actions.CARD_MAP_VIEW_CLEANUP_BEGIN,
    };
  },

  cardMapViewCleanupSuccess: () => {
    return {
      type: actions.CARD_MAP_VIEW_CLEANUP_SUCCESS,
    };
  },

  cardMapViewCleanupErr: err => {
    return {
      type: actions.CARD_MAP_VIEW_CLEANUP_ERR,
      err,
    };
  },

  CARD_MAP_VIEW_SAVE_BEGIN: 'CARD_MAP_VIEW_SAVE_BEGIN',
  CARD_MAP_VIEW_SAVE_SUCCESS: 'CARD_MAP_VIEW_SAVE_SUCCESS',
  CARD_MAP_VIEW_SAVE_ERR: 'CARD_MAP_VIEW_SAVE_ERR',

  cardMapViewSaveBegin: () => {
    return {
      type: actions.CARD_MAP_VIEW_SAVE_BEGIN,
    };
  },

  cardMapViewSaveSuccess: data => {
    return {
      type: actions.CARD_MAP_VIEW_SAVE_SUCCESS,
      data,
    };
  },

  cardMapViewSaveErr: err => {
    return {
      type: actions.CARD_MAP_VIEW_SAVE_ERR,
      err,
    };
  },

  CARD_MAP_VIEW_DELETE_BEGIN: 'CARD_MAP_VIEW_DELETE_BEGIN',
  CARD_MAP_VIEW_DELETE_SUCCESS: 'CARD_MAP_VIEW_DELETE_SUCCESS',
  CARD_MAP_VIEW_DELETE_ERR: 'CARD_MAP_VIEW_DELETE_ERR',

  cardMapViewDeleteBegin: () => {
    return {
      type: actions.CARD_MAP_VIEW_DELETE_BEGIN,
    };
  },

  cardMapViewDeleteSuccess: data => {
    return {
      type: actions.CARD_MAP_VIEW_DELETE_SUCCESS,
      data,
    };
  },

  cardMapViewDeleteErr: err => {
    return {
      type: actions.CARD_MAP_VIEW_DELETE_ERR,
      err,
    };
  },
};

export default actions;
