import actions from './actions';

const initialListState = {
  data: [],
  loading: false,
  error: null,
};

const {
  FILE_FOLDERS_LIST_UPDATE_BEGIN,
  FILE_FOLDERS_LIST_UPDATE_SUCCESS,
  FILE_FOLDERS_LIST_UPDATE_ERR,

  FILE_FOLDERS_LIST_CLEANUP_BEGIN,
  FILE_FOLDERS_LIST_CLEANUP_SUCCESS,
  FILE_FOLDERS_LIST_CLEANUP_ERR,
} = actions;

const fileFoldersListReducer = (state = initialListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FILE_FOLDERS_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FILE_FOLDERS_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case FILE_FOLDERS_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case FILE_FOLDERS_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FILE_FOLDERS_LIST_CLEANUP_SUCCESS:
      return initialListState;

    case FILE_FOLDERS_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialTreeState = {
  data: [],
  loading: false,
  deleting: false,
  error: null,
};

const {
  FILE_FOLDERS_TREE_UPDATE_BEGIN,
  FILE_FOLDERS_TREE_UPDATE_SUCCESS,
  FILE_FOLDERS_TREE_UPDATE_ERR,

  FILE_FOLDERS_TREE_CLEANUP_BEGIN,
  FILE_FOLDERS_TREE_CLEANUP_SUCCESS,
  FILE_FOLDERS_TREE_CLEANUP_ERR,

  FILE_FOLDER_DELETE_BEGIN,
  FILE_FOLDER_DELETE_SUCCESS,
  FILE_FOLDER_DELETE_ERR,
} = actions;

const fileFoldersTreeReducer = (state = initialTreeState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FILE_FOLDERS_TREE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FILE_FOLDERS_TREE_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case FILE_FOLDERS_TREE_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case FILE_FOLDERS_TREE_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FILE_FOLDERS_TREE_CLEANUP_SUCCESS:
      return initialTreeState;

    case FILE_FOLDERS_TREE_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case FILE_FOLDER_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case FILE_FOLDER_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
      };

    case FILE_FOLDER_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  FILE_FOLDER_UPDATE_BEGIN,
  FILE_FOLDER_UPDATE_SUCCESS,
  FILE_FOLDER_UPDATE_ERR,

  FILE_FOLDER_CLEANUP_BEGIN,
  FILE_FOLDER_CLEANUP_SUCCESS,
  FILE_FOLDER_CLEANUP_ERR,

  FILE_FOLDER_SAVE_BEGIN,
  FILE_FOLDER_SAVE_SUCCESS,
  FILE_FOLDER_SAVE_ERR,
} = actions;

const fileFolderReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FILE_FOLDER_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FILE_FOLDER_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case FILE_FOLDER_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case FILE_FOLDER_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FILE_FOLDER_CLEANUP_SUCCESS:
      return initialSingleState;

    case FILE_FOLDER_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case FILE_FOLDER_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case FILE_FOLDER_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case FILE_FOLDER_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { fileFoldersListReducer, fileFoldersTreeReducer, fileFolderReducer };
